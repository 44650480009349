import React from 'react';
import styled from 'styled-components';
import { P, Subtitle5 } from './Typography';

const Wrapper = styled.div`
  display: flex;
  gap: 22px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  border: 1px solid #dbe3eb;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    box-shadow: 0 4px 30px rgba(194, 206, 219, 0.6);
  }
`;
const Img = styled.img``;

const Info = styled.div`
  min-height: 10rem;
  margin-top: 1rem;
`;

const Image = styled.div<{ imageBackground: string }>`
  border-radius: 50%;
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ imageBackground }) =>
    imageBackground && `background-color: ${imageBackground};`}
`;

const ImageLabelWrapper = styled.div`
  display: flex;
  gap: 29px;
  align-items: center;
`;

const StyledP = styled(P)`
  color: #495b6c;
`;

interface Props {
  title: string;
  description: string;
  image: string;
  imageBackground: string;
}
const KeyFeatureVPRsCard: React.FunctionComponent<Props> = ({
  title,
  description,
  image,
  imageBackground,
}) => (
  <Wrapper>
    <ImageLabelWrapper>
      <Image imageBackground={imageBackground}>
        <Img src={image} alt="card logo" />
      </Image>
      <Subtitle5>{title}</Subtitle5>
    </ImageLabelWrapper>

    <Info>
      <StyledP>{description}</StyledP>
    </Info>
  </Wrapper>
);

export default KeyFeatureVPRsCard;
