import React from 'react';
import Main from '../containers/Layout';
import variableRecurringPaymentsContent from '../../content/pages/variable-recurring-payments.yml';
import VariableRecurringPaymentsContainer from '@/containers/VariableRecurringPaymentsContainer';

export interface VariableRecurringPaymentsPageContent {
  viewport1: {
    title1: string;
    title2: string;
    title3: string;
    description: string;
    descriptionItems: {
      item: string;
    }[];
    button: { label: string; url: string };
  };
  viewport2: {
    title: string;
    description1: string;
    description2: string;
    image: string;
  };
  viewport3: {
    label: string;
    keyFeaturesCards: {
      title: string;
      description: string;
      image: string;
      imageBackground: string;
    }[];
  };
  viewport4: {
    title: string;
    descriptionCards: {
      title: string;
      description: string;
      icon: string;
    }[];
    description1: string;
  };
  viewport5: {
    title: string;
    description1: string;
    description2: string;
    keyCards: {
      label: string;
    }[];
    button: {
      label: string;
      url: string;
    };
  };
  viewport6: {
    isComingSoon?: boolean;
    title: string;
    description1: string;
    description2: string;
    bigPurpleCard: {
      title: string;
      leftColumn: { description1: string }[];
      rightColumn: { description1: string }[];
      bottomText: string;
      button: { label: string; url: string };
    };
  };
  viewport7: {
    title: string;
    description: string;
    button: { label: string; url: string };
  };
}
const VariableRecurringPayments: React.FunctionComponent = () => {
  const {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
  } = variableRecurringPaymentsContent as unknown as VariableRecurringPaymentsPageContent;
  return (
    <Main>
      <VariableRecurringPaymentsContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
          viewport7,
        }}
      />
    </Main>
  );
};

export default VariableRecurringPayments;
