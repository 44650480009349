import React from 'react';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';
import FirstViewport from '@/components/FirstViewport';
import { H1, H3, P, StrongInitial } from '@/components/Typography';
import Vp1Image from '../assets/img/vprs-vp1.png';
import TickedItems from '@/components/TickedItems';
import ButtonDefault from '@/components/ButtonDefault';
import Section from '@/components/Section';
import { VariableRecurringPaymentsPageContent } from '@/pages/variable-recurring-payments';
import KeyFeatureVPRsCard from '@/components/KeyFeatureVPRsCard';
import KeyFutureCard from '@/components/KeyFutureCard';
import BigPurpleCard from '@/components/BigPurpleCard';

const FirstViewportWrapper = styled.div`
  background: linear-gradient(123deg, #f4f7f9 45.14%, #c2cedb 100.49%);
`;

const StyledStrong = styled(StrongInitial)`
  margin-top: 2rem;
`;

const DescriptionWrapper = styled.div`
  margin: 2rem 0;
`;

const SecondViewport = styled(Section)`
  display: inline-flex;
  gap: 70px;
  flex-wrap: nowrap;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
`;

const Label = styled(H3)`
  text-align: center;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
  margin-top: 85px;
  flex-wrap: wrap;
  column-gap: 130px;
  row-gap: 49px;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

const KeyCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  margin: 44px 0 84px;
`;

const ViewportsWrapper = styled.div`
  background-color: #fff;
`;

const ThirdViewport = styled(Section)`
  padding-top: 73px;
  padding-bottom: 0;
  background: linear-gradient(180deg, #f4f7f9 0%, #f4f7f900 100%);
`;

const FourthViewport = styled(Section)`
  padding-bottom: 0;
`;

const FifthViewport = styled(Section)`
  padding-top: 2rem;
`;

const SixthViewport = styled(Section)`
  background: linear-gradient(180deg, #f4f7f9 0%, #f4f7f900 100%);
`;

const SeventhViewport = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
`;

const DescriptionCardsWrapper = styled.div`
  display: flex;
  margin: 2rem 0 40px;
  flex-wrap: nowrap;
  gap: 82px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const Vp2LeftWrapper = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Vp2RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Vp2Title = styled(P)`
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  margin: 30px 0 38px;
`;

const Vp2Description = styled(P)`
  color: #495b6c;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
`;

const Vp2Image = styled.img`
  max-height: 360px;
`;

const DescriptionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionCardImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid #dbe3eb;
  margin-bottom: 32px;
`;

const DescriptionCardTitle = styled(P)`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 12px;
`;

const DescriptionCardDescription = styled(P)`
  color: #495b6c;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
`;

const Vp5Description1 = styled(P)`
  color: #0e233e;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 32px;
`;

const Vp5Description2 = styled(P)`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionTitle = styled(P)`
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
`;

const ComingSoonLabel = styled(P)`
  color: #ef6355;
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 10px;
`;

const Vp6Desc1 = styled(P)`
  color: #0e233e;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 34px;
`;

const Vp6Desc2 = styled(P)`
  color: #495b6c;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 45px;
`;

const Vp7Desc = styled(P)`
  color: #495b6c;
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  margin-bottom: 79px;
`;

interface VPRsProps {
  content: VariableRecurringPaymentsPageContent;
}

const VariableRecurringPaymentsContainer: React.FunctionComponent<
  VPRsProps
> = ({
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
  },
}) => (
  <>
    <FirstViewportWrapper>
      <FirstViewport imgWidth="50%" withRightPadding img={Vp1Image}>
        <H1>
          {viewport1.title1}
          <br />
          {viewport1.title2}
          <br />
          <span className="accent-text">{viewport1.title3}</span>
        </H1>
        <StyledStrong>{viewport1.description}</StyledStrong>
        <DescriptionWrapper>
          <TickedItems items={viewport1.descriptionItems} />
        </DescriptionWrapper>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </FirstViewport>
    </FirstViewportWrapper>

    <ViewportsWrapper>
      <SecondViewport>
        <Vp2LeftWrapper>
          <Vp2Image src={viewport2.image} />
        </Vp2LeftWrapper>
        <Vp2RightWrapper>
          <Vp2Title>{viewport2.title}</Vp2Title>
          <Vp2Description style={{ marginBottom: 25 }}>
            {viewport2.description1}
          </Vp2Description>
          <Vp2Description>{viewport2.description2}</Vp2Description>
        </Vp2RightWrapper>
      </SecondViewport>

      <ThirdViewport>
        <Label style={{ textAlign: `left` }}>{viewport3.label}</Label>
        <CardWrapper>
          {viewport3.keyFeaturesCards.map((item, i) => (
            <KeyFeatureVPRsCard
              key={item.title + i.toString()}
              title={item.title}
              description={item.description}
              image={item.image}
              imageBackground={item.imageBackground}
            />
          ))}
        </CardWrapper>
      </ThirdViewport>

      <FourthViewport>
        <SectionTitle>{viewport4.title}</SectionTitle>
        <DescriptionCardsWrapper>
          {viewport4.descriptionCards.map((item, i) => (
            <DescriptionCardWrapper key={item.title + i.toString()}>
              <DescriptionCardImageWrapper>
                <img src={item.icon} alt={item.title} />
              </DescriptionCardImageWrapper>
              <DescriptionCardTitle>{item.title}</DescriptionCardTitle>
              <DescriptionCardDescription>
                {item.description}
              </DescriptionCardDescription>
            </DescriptionCardWrapper>
          ))}
        </DescriptionCardsWrapper>
        <DescriptionCardDescription style={{ marginBottom: 45 }}>
          {viewport4.description1}
        </DescriptionCardDescription>
      </FourthViewport>

      <FifthViewport>
        <SectionTitle style={{ marginBottom: 21 }}>
          {viewport5.title}
        </SectionTitle>
        <Vp5Description1>{viewport5.description1}</Vp5Description1>
        <Vp5Description2>{viewport5.description2}</Vp5Description2>
        <KeyCardWrapper>
          {viewport5.keyCards.map((el) => (
            <KeyFutureCard label={el.label} />
          ))}
        </KeyCardWrapper>
        <ButtonWrapper>
          <ButtonDefault to={viewport5.button.url}>
            {viewport5.button.label}
          </ButtonDefault>
        </ButtonWrapper>
      </FifthViewport>

      <SixthViewport>
        {viewport6.isComingSoon && (
          <ComingSoonLabel>[COMING SOON]</ComingSoonLabel>
        )}
        <SectionTitle style={{ marginBottom: 34 }}>
          {viewport6.title}
        </SectionTitle>
        <Vp6Desc1>{viewport6.description1}</Vp6Desc1>
        <Vp6Desc2>{viewport6.description2}</Vp6Desc2>
        <BigPurpleCard
          title={viewport6.bigPurpleCard.title}
          leftColumn={viewport6.bigPurpleCard.leftColumn}
          rightColumn={viewport6.bigPurpleCard.rightColumn}
          bottomText={viewport6.bigPurpleCard.bottomText}
          button={viewport6.bigPurpleCard.button}
          descriptionStyles={{
            fontSize: `18px`,
            fontWeight: 400,
            color: `#F4F7F9`,
          }}
        />
      </SixthViewport>
    </ViewportsWrapper>
    <SeventhViewport>
      <SectionTitle
        style={{ marginBottom: 14, textAlign: `center`, color: `#1A1A1A;` }}
      >
        {viewport7.title}
      </SectionTitle>
      <Vp7Desc style={{ textAlign: `center` }}>{viewport7.description}</Vp7Desc>
      <ButtonDefault to={viewport7.button.url}>
        {viewport7.button.label}
      </ButtonDefault>
    </SeventhViewport>
  </>
);
export default VariableRecurringPaymentsContainer;
